let menuTrigger;
let langTrigger;

$(function() {
    menuTrigger =  $('.js-menu-trigger');
    langTrigger =  $('.js-lang-trigger');
    menuTrigger.on('click', toggleMenu);
    langTrigger.on('click', toggleLang);
    $(document).mouseup(closePopups);
});


function closePopups(e) {
    var container = $('.c-header');

    // if the target of the click isn't the container nor a descendant of the container
    if (!container.is(e.target) && container.has(e.target).length === 0) 
    {
        $('.js-menu-wrapper').removeClass('is-active');
        $('.js-lang-list').removeClass('is-active');
    }
}

function toggleMenu(e) {
    e.preventDefault();
    $('.js-menu-wrapper').toggleClass('is-active');
}

function toggleLang(e) {
    e.preventDefault();
    $('.js-lang-list').toggleClass('is-active');
}