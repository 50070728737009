let targetAccordion, targetVisual, trigger;

$(function() {
    $('.js-discover-trigger').on('click', trigger);
});

function trigger(e) {
    trigger = $(e.currentTarget);
    targetVisual = $(`[data-discover-element-visual="${trigger.attr('data-discover-target')}"]`);
    $('[data-discover-element-visual]').removeClass('is-active');
    targetVisual.addClass('is-active');
    $('[data-discover-element]').slideUp();
    targetAccordion = $(`[data-discover-element="${trigger.attr('data-discover-target')}"]`);
    targetAccordion.slideDown();
    $('.js-discover-trigger').removeClass('is-active');
    $(`[data-discover-target="${trigger.attr('data-discover-target')}"]`).addClass('is-active');
}

